<template>

  <div>
    <section class="promo-card-wrapper mb-3">
      <div class="promo-card-header text-light p-2 d-flex" data-toggle="collapse" data-target="#collapseChachisha" aria-expanded="false" aria-controls="collapseChachisha">
        <div class="pt-2 pr-2">
          <img src="img/other/comment.svg" class="side-icon-svg mr-2">
        </div>

        <div class="d-flex justify-content-between width-available">
          <div>
            <span class="text-blue small-text">AFCON</span> <span class="text-light small-text">12/01/22, 19:30</span> <br>
            <strong>MAURITANIA VS GAMBIA</strong>
          </div>
          <div class="tips-arrow">
            <i class="bi bi-chevron-right text-blue"></i>
          </div>

        </div>


      </div>
      <div class="promo-card-subheader text-center p-2" data-toggle="collapse" data-target="#collapseChachisha" aria-expanded="false" aria-controls="collapseChachisha">

        <div class="text-light text-underline">
          Show Tips And Predictions
        </div>

      </div>
      <div class="promo-content text-light collapse p-2" id="collapseChachisha">
        <!--Some html static content-->
        <div class="text-light width-available text-left">
          <strong>
            Under 2.5
          </strong>
        </div>
        <p>
          Most games played so far in 2022 AFCON tournament in Cameroon have had fewer than the goals. We don’t think this fixture will be any different. It seems most teams are cautious going forward. Al three previous meetings between Mauritania and Gambia recorded fewer than three goals. We see a similar score line in this 11th game of the AFCON 2022 tournament.
        </p>
        <div class="text-light width-available text-left">
          <strong>
            Gambia to open the scoring
          </strong>
        </div>
        <p>
          Expectations on both sides are high but winning this match not only boils down to who is hungrier for playoffs but also defensive prowess of both teams. Looking at the last ten games, Mauritania has had an awful defensive performance compared to Gambia. Mauritania lost six of their last ten games and bagged only one victory within that duration. On the other hand, Gambia recorded 5 wins, 4 losses and one draw in as many previous matches.
        </p>

        <!--<div class="text-light width-available text-left">
        <strong>
          Total Goals – Over 2.5 Goals
        </strong>
      </div>
        <p>
          We're not expecting too many goals in this game as six of the last seven encounters between these two clubs have produced under 2.5 goals. Under 2.5 goals have also been scored in four of Man Utd's last five outings as well as in each of Wolves' last six fixtures, adding further weight to our prediction for a tight and tense 90-minutes at Old Trafford. Man Utd have only scored more than one goal in two of their last six matches, while Wolves have failed to score in five of their last six league games.
        </p>-->

        <div class="mb-2 text-center">
          <a href="https://www.FalconBet.ke/match/prematch/4330/Mauritania-vs-Gambia" class="join-button py-2 px-5 ">BET NOW</a>
        </div>

      </div>
    </section>

    <section class="promo-card-wrapper mb-3">
      <div class="promo-card-header text-light p-2 d-flex" data-toggle="collapse" data-target="#collapseChachisha1" aria-expanded="false" aria-controls="collapseChachisha1">
        <div class="pt-2 pr-2">
          <img src="img/other/comment.svg" class="side-icon-svg mr-2">
        </div>

        <div class="d-flex justify-content-between width-available">
          <div>
            <span class="text-blue small-text">AFCON</span> <span class="text-light small-text">12/01/22, 16:00</span> <br>
            <strong>TUNISIA VS MALI</strong>
          </div>
          <div class="tips-arrow">
            <i class="bi bi-chevron-right text-blue"></i>
          </div>

        </div>


      </div>
      <div class="promo-card-subheader text-center p-2" data-toggle="collapse" data-target="#collapseChachisha1" aria-expanded="false" aria-controls="collapseChachisha1">

        <div class="text-light text-underline">
          Show Tips And Predictions
        </div>

      </div>
      <div class="promo-content text-light collapse p-2" id="collapseChachisha1">
        <!--Some html static content-->
        <div class="text-light width-available text-left">
          <strong>
            Mali to win
          </strong>
        </div>
        <p>
          Backing Mali to win this match could come with hard luck but we have every reason to settle on this audacious prediction. This is a fixture between some of the best teams in the AFCON tournament, so we expect no room for mistakes in this game. But looking at how both sides have performed in recent games, Mali looks better defensively and offensively. Prior to losing against Malawi in a friendly fixture in a 2022 pre-AFCON friendly, Mali bagged six clean sheets in six games, five of which were victories. On the other hand, Tunisia comes to this game after losing to Algeria in Arab Cup knockout. They were doing well but occasional losses highlighted defensive loops that continue to show in Tunisia’s defence.
        </p>
        <div class="text-light width-available text-left">
          <strong>
            BTTS: No
          </strong>
        </div>
        <p>
          We don’t expect goals on both ends of the pitch in this match. Tunisia will need to plug their defensive loopholes to get a result in this game, especially against a team a team like Mali that boasts a solid defense. In the last eight games, Tunisia kept only two clean sheets. Mali comes to this match with six clean sheets in as many run of matches. A solid defense a good scoring record is why we are giving Mali the advantage to win this game without conceding a goal.
        </p>

        <!--<div class="text-light width-available text-left">
        <strong>
          Both Teams to Score – Yes
        </strong>
      </div>
        <p>
          Five of the last six head-to-heads between these two sides have ended with both teams getting on the scoreboard. Both teams have also found the back of the net in four of Atalanta's last six outings in league and cup, while Roma have only failed to score in two of their last six fixtures.
        </p>-->

        <div class="mb-2 text-center">
          <a href="https://www.FalconBet.ke/match/prematch/4329/Tunisia-vs-Mali" class="join-button py-2 px-5 ">BET NOW</a>
        </div>

      </div>
    </section>

    <section class="promo-card-wrapper mb-3">
      <div class="promo-card-header text-light p-2 d-flex" data-toggle="collapse" data-target="#collapseChachisha2" aria-expanded="false" aria-controls="collapseChachisha2">
        <div class="pt-2 pr-2">
          <img src="img/other/comment.svg" class="side-icon-svg mr-2">
        </div>

        <div class="d-flex justify-content-between width-available">
          <div>
            <span class="text-blue small-text">SUPERCUP</span> <span class="text-light small-text">12/01/22, 23:00</span> <br>
            <strong>INTER MILAN VS JUVENTUS</strong>
          </div>
          <div class="tips-arrow">
            <i class="bi bi-chevron-right text-blue"></i>
          </div>

        </div>


      </div>
      <div class="promo-card-subheader text-center p-2" data-toggle="collapse" data-target="#collapseChachisha2" aria-expanded="false" aria-controls="collapseChachisha2">

        <div class="text-light text-underline">
          Show Tips And Predictions
        </div>

      </div>
      <div class="promo-content text-light collapse p-2" id="collapseChachisha2">
        <!--Some html static content-->
        <div class="text-light width-available text-left">
          <strong>
            Inter Milan to Win
          </strong>
        </div>
        <p>
          Juventus were involved in an incredible 4-3 comeback victory at Roma on Sunday and though their triumph was euphoric, their performance had enough holes to suggest they might be in trouble against a more robust Inter team in the Super Cup. The hosts have won nine of their last ten matches in all competitions and they have the ruthlessness to take advantage of Juve’s lackluster defending. Inter are targeting their eighth straight win at San Siro this week and we’re tipping Simone Inzaghi’s side to make home advantage count here.
        </p>

        <div class="text-light width-available text-left">
          <strong>
            Both Teams to Score
          </strong>
        </div>
        <p>
          BTTS has been a winning bet in three of the last four meetings between Inter Milan and Juventus, and with both teams more comfortable going forward than they are defending, we expect action at either end again this week. There has been just one goalless draw between these Italian giants since 2017 and with silverware on the line, both outfits will be pushing for victory from the first whistle. We don’t envisage any clean sheets being secured in that scenario, so back both teams to score in Milan.
        </p>

        <!--<div class="text-light width-available text-left">
          <strong>
            Both Teams to Score – No
          </strong>
        </div>
        <p>
          Although both teams have scored in three of the previous five h2h duels between Arsenal and Sunderland, their last meeting was a long time ago and those games are hardly representative of what we can expect now.
          So, we don’t expect both teams to score in this game. We chose this market to stay on the safe side, but we would also be comfortable to place a bet on Arsenal keeping a clean sheet or winning by nil, which come at higher odds. After all, Arsenal has yet to concede this season in EFL Cup.
        </p>-->

        <div class="mb-2 text-center">
          <a href="https://www.FalconBet.ke/match/prematch/1405/Inter-Milano-vs-Juventus-Turin" class="join-button py-2 px-5 ">BET NOW</a>
        </div>

      </div>
    </section>

    <!--<section class="promo-card-wrapper mb-3">
      <div class="promo-card-header text-light p-2 d-flex" data-toggle="collapse" data-target="#collapseChachisha3" aria-expanded="false" aria-controls="collapseChachisha3">
        <div class="pt-2 pr-2">
          <img src="img/other/comment.svg" class="side-icon-svg mr-2">
        </div>

        <div class="d-flex justify-content-between width-available">
          <div>
            <span class="text-blue small-text">SERIE A</span> <span class="text-light small-text">09/01/22, 19:00</span> <br>
            <strong>TORINO VS FIORENTINA</strong>
          </div>
          <div class="tips-arrow">
            <i class="bi bi-chevron-right text-blue"></i>
          </div>

        </div>


      </div>
      <div class="promo-card-subheader text-center p-2" data-toggle="collapse" data-target="#collapseChachisha3" aria-expanded="false" aria-controls="collapseChachisha3">

        <div class="text-light text-underline">
          Show Tips And Predictions
        </div>

      </div>
      <div class="promo-content text-light collapse p-2" id="collapseChachisha3">
        &lt;!&ndash;Some html static content&ndash;&gt;
        <div class="text-light width-available text-left">
          <strong>
            Torino to Win
          </strong>
        </div>
        <p>
          Torino have been doing almost all of their good work at home this season and remarkably, only league leaders Inter have collected more points than Il Toro on their own patch since the summer. The hosts take a six-match unbeaten home run into Sunday’s test against Fiorentina and Torino’s ability to carve out result at the Stadio Olimpico Grande Torino could give them the edge again when an inconsistent La Viola outfit visit.
        </p>

        <div class="text-light width-available text-left">
          <strong>
            Both Teams to Score
          </strong>
        </div>
        <p>
          Torino have been bagging plenty of points at home in recent weeks, though securing clean sheets to complement their impressive results has been trickier task. Indeed, both teams have scored in four of Il Toro’s last six assignments on home soil and when you consider that the same bet landed in each of Fiorentina’s last three away tussles, circumstances look to be in our favour here. Both teams found the net in each of the last two encounters between Torino and Sunday’s opponents and we’re tipping that trend to continue.
        </p>

        &lt;!&ndash;<div class="text-light width-available text-left">
          <strong>
            Both Teams to Score – No
          </strong>
        </div>
        <p>
          Although both teams have scored in three of the previous five h2h duels between Arsenal and Sunderland, their last meeting was a long time ago and those games are hardly representative of what we can expect now.
          So, we don’t expect both teams to score in this game. We chose this market to stay on the safe side, but we would also be comfortable to place a bet on Arsenal keeping a clean sheet or winning by nil, which come at higher odds. After all, Arsenal has yet to concede this season in EFL Cup.
        </p>&ndash;&gt;

        <div class="mb-2 text-center">
          <a href=" https://FalconBet.ke/match/prematch/10292/Torino-FC-vs-ACF-Fiorentina" class="join-button py-2 px-5 ">BET NOW</a>
        </div>

      </div>
    </section>-->

    <!--<section class="promo-card-wrapper mb-3">
      <div class="promo-card-header text-light p-2 d-flex" data-toggle="collapse" data-target="#collapseChachisha4" aria-expanded="false" aria-controls="collapseChachisha4">
        <div class="pt-2 pr-2">
          <img src="img/other/comment.svg" class="side-icon-svg mr-2">
        </div>

        <div class="d-flex justify-content-between width-available">
          <div>
            <span class="text-blue small-text">EFL CUP</span> <span class="text-light small-text">05/01/22, 22:45</span> <br>
            <strong>CHELSEA VS TOTTENHAM</strong>
          </div>
          <div class="tips-arrow">
            <i class="bi bi-chevron-right text-blue"></i>
          </div>

        </div>


      </div>
      <div class="promo-card-subheader text-center p-2" data-toggle="collapse" data-target="#collapseChachisha4" aria-expanded="false" aria-controls="collapseChachisha4">

        <div class="text-light text-underline">
          Show Tips And Predictions
        </div>

      </div>
      <div class="promo-content text-light collapse p-2" id="collapseChachisha4">
        &lt;!&ndash;Some html static content&ndash;&gt;
        <div class="text-light width-available text-left">
          <strong>
            Chelsea win
          </strong>
        </div>
        <p>
          There is no denying that Tottenham look like a better side since Antonio Conte arrived in North London but that doesn't mean much; they were poor before he took post. Chelsea meanwhile are in a poor run of form – for them – having won just four of their past 10 games. That said, they have only lost once in that period. A big plus point for the Blues coming into this game is how good they looked against Liverpool at the weekend; that combined with the fact Spurs have looked far from convincing in their last couple of games is enough for us to back Chelsea for the win. the head to head record supports that view too; Chelsea have won the last two competitive head to heads.
        </p>

        <div class="text-light width-available text-left">
          <strong>
            Both teams will score
          </strong>
        </div>
        <p>
          With our second tip, we are backing both teams to score goals in this match. Chelsea, despite their slight wobble in form, have found goals easy enough to come by. They have blanked in just one of their past 10 matches. Tottenham's story is similar. A lot has been made of Harry Kane lacking that goal scoring touch but his team are doing okay; they too have failed to score in just one their last 10 games; even that was 10 games ago as well!
        </p>

        &lt;!&ndash;<div class="text-light width-available text-left">
          <strong>
            Both Teams to Score – No
          </strong>
        </div>
        <p>
          Although both teams have scored in three of the previous five h2h duels between Arsenal and Sunderland, their last meeting was a long time ago and those games are hardly representative of what we can expect now.
          So, we don’t expect both teams to score in this game. We chose this market to stay on the safe side, but we would also be comfortable to place a bet on Arsenal keeping a clean sheet or winning by nil, which come at higher odds. After all, Arsenal has yet to concede this season in EFL Cup.
        </p>&ndash;&gt;

        <div class="mb-2 text-center">
          <a href="https://FalconBet.ke/match/prematch/4319/Chelsea-FC-vs-Tottenham-Hotspur" class="join-button py-2 px-5 ">BET NOW</a>
        </div>

      </div>
    </section>-->

  </div>

</template>

<script>

export default {
  name: 'Tips',
  components: {
  },
  mounted: function () {

    this.$store.dispatch("setCurrentPage","tips");
    this.reloadProfile();

  }

}
</script>