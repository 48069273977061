<template>
  <div class="wrapper">
    <Slider></Slider>

    <section class="mb-3">
      <div class="form-wrapper mb-0" v-show="!results">
        <div class="input-group" style="border: 1px black solid;border-radius: 5px;background-color: white;color: black;width: 100%;height: 40px;text-align: center;">
          <input @keyup.enter="search" type="text" class="form-control" id="search-input"
          name="keyword" placeholder="Search for games, leagues, events" aria-label="Search for games, leagues, events"
          v-model="to_search">
          
          <div @click="search" class="input-group-prepend" style="border-radius: 5px;background-color: white;color: black;text-align: center;">
            <button style="background-color: var(--yellow);" class="btn btn-sm" type="button"><i style="color: var(--grey);" class="bi bi-search mr-1"></i></button>
          </div>
        </div>
      </div>

      <!-- <div class="text-center py-2 px-2">
        <btn style="background-color: #990808;" @click="search" class="copy-betslip-btn1 text-white py-2 form-control smaller-font"
          > <span style="color: white;">Search</span></btn>
      </div> -->

      <!-- <hr class="mb-1" /> -->
      <Triviahunt v-if="shouldDisplayTrivia" :triviaLink="triviaLink"></Triviahunt>

      <!-- <hr class="mb-1" /> -->
      <div class="c-a-2 text-center" v-if="results && results.length > 0">
        <strong>Search Results</strong>
      </div>
      <!-- <hr class="mb-2" /> -->
      
      <Games
        v-bind:sport="sport_id"
        v-bind:search="keywords"
        v-bind:searchable="searchable"
        v-on:search-results="handleSearchResults"
      ></Games>

      <div class="text-center slip-ttxt py-3 d-none">
        <div class="c-a-2 mb-3 text-dark">Get the bitsets games booklet for free</div>
        <div>
          <a href="" class="login-button py-2 px-4"
            ><span style="color: black;">Download latest games booklet</span></a
          >
        </div>
      </div>

      <div v-show="no_results" class="c-a-2 text-center d-none text-dark">
        No results found for<br />
        "{{ to_search }}"
      </div>
    </section>
  </div>
</template>

<script>
//import Menu from './Menu'

import Games from "./Games";
import Triviahunt from "./Triviahunt.vue";
const Slider = () => import('./Slider.vue');

export default {
  name: "Search",
  components: {
    Games,
    Slider,
    Triviahunt,
  },
  data: function () {
    return {
      sport_id: 1,
      searchable: true,
      triviaLink: "https://qz.app.do/furahiday-punch-ya-lunch-trivia-2024",
      to_search: "",
      keywords: "",
      sub_page: "",
      results: "",
      busy: false,
      no_results: false,
      
    };
  },
  mounted() {
    this.$store.dispatch("setCurrentPage", "search");
    this.reloadProfile();
    this.getBonus();
    // reset game filter
    this.$store.dispatch("resetAllGames");
  },
  computed: {
    betslip_count: function () {
      return this.$store.state.betslip.total;
    },
    current_sub_page: function () {
      return this.$store.state.current_sub_page;
    },
    current_page: function () {
      return this.$store.state.current_page;
    },
    shouldDisplayTrivia() {
      const now = new Date();
      const startDate = new Date("2024-05-05T13:06:00");
      const endDate = new Date("2024-05-05T16:30:00");

      return (
        now >= startDate &&
        now <= endDate
      );
    },
  },
  methods: {
    handleSearchResults(results) {
      // Check if there are search results and update the visibility of the search input
      this.results = results.length > 0;
    },
    getKey: function (fixture, index) {
      if (Array.isArray(fixture)) {
        var currentFixture = fixture[0];
      } else {
        currentFixture = fixture;
      }

      var prefix =
        currentFixture === undefined || currentFixture.match_id == undefined
          ? index
          : currentFixture.match_id;
      return Math.random()
        .toString(10)
        .replace("0.", "fixture-id-" + prefix + "-");
    },

    setSubPage: function (page) {
      this.$store.dispatch("setCurrentSubPage", page);
      this.sub_page = page;
    },
    geSubPageActiveClass: function (subpage) {
      return subpage === this.sub_page ? "active" : "";
    },
    setSport: function (sportID) {
      this.$store.dispatch("setSportID", sportID);
    },
    search: function () {
      var vm = this;
      // console.log("This vm", vm);
      vm.keywords = vm.to_search;
    },
  },
};
</script>
