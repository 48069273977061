<template>
  <div>
    <section class="selfcare-title d-flex">
      <div class="px-2">
        <a @click="goBack()" class="custom-link text-dark">
          <i class="bi bi-arrow-left"></i>
        </a>
      </div>
      <div class="text-dark width-available text-center">
        <h3 style="color: var(--blackText);">
          Offers & Promotions
        </h3>
      </div>
    </section>

    <div class="promotions">
      <div class="list">
        <div style="background-color: #848482;" class="card" v-for="(promotion, index) in promotions" :key="index">
          <img :src="`/img/carousel/` + promotion.img" alt="Promotion image" style="max-width: 100%; height: auto;" />
          <div class="content">
            <h3>{{ promotion.title }}</h3>
            <p>{{ promotion.description }}</p>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'Offers',
  data() {
    return {
      promotions: [
        {
          title: "Karibu Bonus - Get free cash on registration",
          description: "Start your winning journey right away! Sign up and instantly receive free cash to boost your bets. " +
            "No deposits, no strings attached. Claim your free boost and experience the thrill of online betting, risk-free!",
          // img: "carousel_3.png"
          img: "karibu1.png"
        },
        {
          title: "Crash On - Mamili",
          description: "Crash on mamili KI PRO MAX na Jet X",
          // img: "crash-mamilli.png"
          img: "cheza_leo.png"
        },
        {
          title: "Share And - Earn",
          description: "Share your unique referral code widely & earn 5% of their deposit when they sign up !",
          // img: "carousel_4.png"
          img: "djp1.png"
        },
        {
          title: "Bahatika - Jackpot",
          description: "CHEZA LEO ujinyakulie 100K with our BAHATIKA DAILY JACKPOT",
          // img: "JACKPOT.png"
          img: "freespin1.png"
        },
        // {
        //   title: "Jishikie Upto - 500% Cash Back",
        //   description: "Bet ikichomeka usijam sana ! FalconBet tunakupa upto 500% cash back.",
        //   // img: "cash-back.png"
        //   img: "bet_ikichomeka.jpeg"
        // },
        // {
        //   title: "Crash On Mamili ki PRO MAX ",
        //   description: "Cash in Kabla ya crashout na Comet Crash",
        //   img: "comet-crash.png"
        // },
        // {
        //   title: "Sign Up and Win",
        //   description: "Sign up na FalconBet and win yourself wins Moto Moto.",
        //   img: "general-banner.png"
        // },
        // Add more promotions with titles and descriptions ...
      ],
    };
  },
  components: {},
  mounted: function () {

    this.$store.dispatch("setCurrentPage", "offers");
    this.reloadProfile();

  }

}
</script>

<style scoped>
.promotions {
  padding: 20px;
}

.title {
  font-size: 24px;
  margin-bottom: 20px;
}

.list {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  flex-direction: column;
  color: #000000;
}

.card {
  width: 100%;
  flex-grow: 1;
  background-color: var(--primary);
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 15px;
  color: #fff;
}

.card img {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.card h3 {
  font-size: 18px;
  margin: .5em 0;
}

.card p {
  line-height: 1.6;
}
</style>