<template>

  <div class="">

    <div class="text-center py-3">
      <span class="yellow-light-title">FREE BET FOR NEW CUSTOMERS</span> <br>
      <span class="text-light text-heavier">CLAIM YOUR FREE BET</span><br>
      <span class="text-light small-text">Expires on {{ formatMatchDate(fixture.date) }}</span> <br>
      <span class="text-yellow">KSH. 30/= FREE</span>
    </div>

    <div class="freebet-match p-2">

      <div class="boosted-odds-card-freebet mr-1">

        <div class="grey-text mb-2 league-text">
          {{ fixture.tournament }} <span class="text-light">#{{ fixture.game_id }}</span>
        </div>

        <div class="boosted-odds-game-row row px-2">

          <div class="col-4">

            <div class="text-light text-center text-home-away-team">
              {{ fixture.competitor_1 }}
            </div>

            <div class="grey-text text-center odd-indicator">
              1
            </div>

            <div class="btn-wrapper">
              <button
                  class="btn odd-btn"
                  :class="getClasses(fixture.home)"
                  :sport_id="fixture.sport_id"
                  specifier=""
                  :match_id="fixture.match_id"
                  :id="fixture.home.odd_id"
                  @click="pickOutcome(fixture.home)"
                  v-bind:disabled="parseInt(fixture.home.active) === 0 || parseInt(fixture.home.status) !== 0">
                    <div>
                      <span v-if="parseInt(fixture.home.active) === 0 || parseInt(fixture.home.status) !== 0"> - </span>
                      <span v-else> {{ fixture.home.odd }} </span>
                    </div>
              </button>
            </div>

          </div>

          <div class="col-4">
            <div class="text-transparent text-center text-home-away-team">
              Draw
            </div>
            <div class="grey-text text-center odd-indicator">
              X
            </div>

            <div class="btn-wrapper">
              <button
                  class="btn odd-btn"
                  :class="getClasses(fixture.draw)"
                  :sport_id="fixture.sport_id"
                  specifier=""
                  :match_id="fixture.match_id"
                  :id="fixture.draw.odd_id"
                  @click="pickOutcome(fixture.draw)"
                  v-bind:disabled="parseInt(fixture.draw.active) === 0 || parseInt(fixture.draw.status) !== 0">
                <div>
                  <span v-if="parseInt(fixture.draw.active) === 0 || parseInt(fixture.draw.status) !== 0"> - </span>
                  <span v-else> {{ fixture.draw.odd }} </span>
                </div>
              </button>
            </div>

          </div>

          <div class="col-4">

            <div class="text-light text-center text-home-away-team">
              {{ fixture.competitor_2 }}
            </div>

            <div class="grey-text text-center odd-indicator">
              2
            </div>


            <div class="btn-wrapper">
              <button
                  class="btn odd-btn"
                  :class="getClasses(fixture.away)"
                  :sport_id="fixture.sport_id"
                  specifier=""
                  :match_id="fixture.match_id"
                  :id="fixture.away.odd_id"
                  @click="pickOutcome(fixture.away)"
                  v-bind:disabled="parseInt(fixture.away.active) === 0 || parseInt(fixture.away.status) !== 0">
                  <div>
                    <span v-if="parseInt(fixture.away.active) === 0 || parseInt(fixture.away.status) !== 0"> - </span>
                    <span v-else> {{ fixture.away.odd }} </span>
                  </div>
              </button>
            </div>

          </div>

        </div>

      </div>

    </div>

    <section class="logon-sec px-2">

      <div v-if="!action_verify_password" class="yellow-light-title text-center">
        EASY! <br>
        Just login below and select your odd <br>
        above to claim your FREE BET
      </div>

      <div v-if="!action_verify_password" class="text-center text-light mb-3">
        Enter your phone number and Password below
      </div>

      <div v-if="action_verify_password" class="yellow-light-title text-center">
        Verify Account! <br>Enter Verification code send to your phone
      </div>

      <div v-show="action_verify_password" class="form-wrapper mb-3">

        <div class="input-group mb-0">

          <div class="input-group-prepend">
            <span class="input-group-text"><i class="bi bi-phone"></i></span>
          </div>
          <input type="text" class="form-control" name="password" placeholder="XXXX" aria-label="Verification Code" v-model="code">
          <div class="input-group-append">
            <span class="input-group-text"></span>
          </div>
        </div>

        <small class="text-blue mb-3">Enter the verification code sent to your phone</small>

        <div class="button-wrapper text-center mb-3">
          <button v-bind:class="loading" @click="verifyAccount" class="join-button py-2 form-control">Claim Now Ksh. 30/= FREE</button>
        </div>

      </div>

      <div v-show="action_login" class="form-wrapper mb-3">

        <div class="input-group mb-3">

          <div class="input-group-prepend">
            <span class="input-group-text"><i class="bi bi-phone"></i></span>
          </div>

          <input type="text" class="form-control" name="mobile"  placeholder="Phone number 07 or 01" aria-label="Amount (to the nearest dollar)" v-model="msisdn">

          <div class="input-group-append">
            <span class="input-group-text"></span>
          </div>

        </div>

        <div class="input-group mb-0">

          <div class="input-group-prepend">
            <span class="input-group-text"><i class="bi bi-phone"></i></span>
          </div>
          <input type="password" class="form-control" name="password" placeholder="XXXX" aria-label="Amount (to the nearest dollar)" v-model="password">
          <div class="input-group-append">
            <span class="input-group-text"></span>
          </div>
        </div>

        <small class="text-blue mb-3">Enter the password sent to your phone</small>

        <div class="button-wrapper text-center mb-3">
          <button v-bind:class="loading" @click="login" class="join-button py-2 form-control">Claim Now Ksh. 30/= FREE</button>
        </div>
      </div>

      <div v-show="action_login" class="login-button-wrapper text-center p-3 mb-1 ">
        <div class="text-center text-light mb-3">
          Don't have an account? <br>
          Easy! Join FalconBet and <br>
          Claim your FREE BET NOW?
        </div>
        <a href="register" class="login-button py-2 form-control">Join Now</a>
      </div>

      <div v-show="action_reedem" class="button-wrapper text-center mb-3">
        <button v-bind:class="loading" @click="placeBet" class="join-button py-2 form-control">Claim Now Ksh. 30/= FREE</button>
      </div>

    </section>

  </div>

</template>

<script>

import axios from "@/services/api";

export default {
  name: 'Freebet',
  components: {

  },
  data: function () {
    return {
      match_id: 0,
      game_id: 0,
      fixture: {},
      initial_fixture: {},
      market_group: {
        id: 0
      },
      totals: [],
      live: false,
      seconds: 0,
      possible_win: '',
      payout: '',
      tax: '',
      odd_id: '',
      outcome: {},
      msisdn: '',
      password:'',
      loading:'',
      code:'',
      action_verify_password: false,
      profile: {},
      action_success: false,
      message: ''
    }
  },
  mounted() {

    this.$store.dispatch("setCurrentPage","freebet");
    this.getMatch();
    this.reloadProfile();

  },
  computed: {

    successBagroundImg: function (){

      return this.getURL('/assets/icons/bet_placed.svg');
    },
    action_login: function (){

      var p = this.getProfile();
      return !p && !this.action_verify_password

    },
    action_reedem: function (){

      var p = this.getProfile();
      return p && !this.action_success;

    },
    stake : function (){

      return 30;

    },
    marketGroups: function () {

      return this.$store.state.market_groups
    },
    visibleMarkets: function () {

      return this.fixture.market;
      /*
      var max = 10;
      var x = 0;
      var markets = [];

      this.jQuery.each(this.fixture.market,function(k,v){

        x++;
        if(x < max ) {

          markets.push(v);
        }

      });

      return markets;

       */
    },
    collapsibleMarkets: function () {

      var max = 9;
      var x = 0;
      var markets = [];

      this.jQuery.each(this.fixture.market,function(k,v){

        x++;

        if(x > max ) {

          markets.push(v);
        }

      });

      return [];
    },
    home_team: function () {

      return this.getHomeCompetitorName(this.fixture.name)
    },
    away_team: function () {

      return this.getAwayCompetitorName(this.fixture.name)
    },
    event_time: function () {

      var minutes = parseInt(this.seconds / 60);
      //var seconds = this.seconds % 60;

      var min = minutes;

      if(minutes < 10 ) {

        min = "0"+min;
      }


      //console.log('secs '+sec);

      return min+"'";

      //return min+":"+sec;

    },
  },
  methods: {
    getClasses: function (o) {

      if(o === undefined || this.odd_id === undefined || o === false ) {

        return " match-" + this.fixture.match_id;

      }

      var isPicked = o.odd_id === this.odd_id ? 'picked': '';

      var st = parseInt(o.active) === 0 || parseInt(o.status) !== 0 ? '' : '';
      return st + " "+isPicked+" match-" + this.fixture.match_id;
    },

    placeBet: function () {

      this.reset();
      var p = this.getProfile();

      if(!p) {

        this.setError("Login","Please login to proceed");
        this.action_login = true;
        //this.$router.push({ name: 'login', params: { } });
        return;
      }

      var bet_amount = this.stake;

      var bets = [];

      bets.push({
        odd_id: this.odd_id,
        match_id: this.fixture.match_id
      });

      var utm_source = this.getValue("utm_source");
      var utm_medium = this.getValue("utm_medium");
      var utm_campaign = this.getValue("utm_campaign");
      var referrer = this.getValue("referrer");

      var data = {
        stake: bet_amount,
        bets: bets,
        bet_type: 1,
        source: this.isMobile() ? 2 : 1,
        utm_source: utm_source,
        utm_medium: utm_medium,
        utm_campaign: utm_campaign,
        referrer: referrer
      };

      this.loading = 'loading';

      var vm = this;

      var path = process.env.VUE_APP_URL_BET.replace("{profile_id}", p.d);

      axios.post(path, JSON.stringify(data),{
        headers: {
          'api-key': vm.getAuth()
        },
      })
          .then(res => {

            vm.loading = '';
            var profile = res.data.message.profile;
            var message = res.data.message.message;
            vm.message = message;
            vm.setProfile(profile);

            vm.action_success = true;
            vm.action_reedem = false;
            vm.action_login = false;
            vm.action_verify_password = false;
            vm.setSuccess("Success", message);
            vm.goTo('home');

          })
          .catch(err => {

            vm.loading = '';
            if (err.response) {

              if(parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400 || parseInt(err.response.status) === 428 ) {

                vm.setError("Session Expired", "Your session on this device has expired");
                vm.logout();
                return;

              } else {

                vm.setError("Failed", err.response.data.message)
                console.log(JSON.stringify(err.response.data.message));

              }

            } else if (err.request) {

              vm.setError("Network Error", "Check your network connection and try again")
              console.log(JSON.stringify(err.request));

            } else {

              vm.setError("Network Error", "Check your network connection and try again")
              console.log(JSON.stringify(err));

            }
          })
    },

    login: function () {

      this.reset();
      this.removeAuth();

      if (this.msisdn.length < 9) {

        this.setError("Invalid Mobile Number", "Please enter a valid mobile number");
        return;

      }

      if (this.password.length < 4) {

        this.setError("Invalid Password", "Please enter a password of more than 4 characters");
        return;

      }

      this.loading = 'loading';

      var vm = this;

      var path = process.env.VUE_APP_URL_LOGIN;

      var utm_source = this.getValue("utm_source");
      var utm_medium = this.getValue("utm_medium");
      var utm_campaign = this.getValue("utm_campaign");
      var referrer = this.getValue("referrer");


      axios.post(path, JSON.stringify({
        m: vm.getBackendValue(this.msisdn),
        password: this.password,
          utm_source: utm_source,
          utm_medium: utm_medium,
          utm_campaign: utm_campaign,
          referrer: referrer
      }))
          .then(res => {

            vm.loading = '';
            var status = res.data.status;
            var profile = res.data.message;

            if(parseInt(status) === 201 ) {

                vm.action_verify_password = true;
                vm.profile = profile;
                vm.setSuccess("Success", "Account created successful");
                return;
            }

            vm.setProfile(profile);
            var auth = profile.auth;
            vm.setAuth(auth);

            vm.setSuccess("Success", "Login successful");
            vm.placeBet();

          })
          .catch(err => {

            vm.loading = '';
            if (err.response) {

              vm.setError("Failed",err.response.data.message)
              if(parseInt(err.response.status) === 428 ) {

                vm.action_verify_password = true;
                return;
              }

            }
            else if (err.request) {

              vm.setError("Network Error", "Check your network connection and try again")

            }
            else {

              console.log(JSON.stringify(err,undefined,4));

            }

          })

    },

    verify: function () {

      this.reset();
      this.removeAuth();


      if (this.code.length < 4) {

        this.setError("Invalid Password", "Please enter a password of more than 4 characters");
        return;

      }

      this.loading = 'loading';

      var vm = this;

      var path = process.env.VUE_APP_URL_LOGIN;

      var currentDate = new Date();
      var login_tag = parseInt(currentDate.getTime()+''+Math.floor(Math.random() * (9 * (Math.pow(10, 2)))) + (Math.pow(10, 2)));
      this.setValue("login_tag",login_tag);

      var utm_source = this.getValue("utm_source");
      var utm_medium = this.getValue("utm_medium");
      var utm_campaign = this.getValue("utm_campaign");
      var referrer = this.getValue("referrer");

      axios.post(path, JSON.stringify({
        m: vm.getBackendValue(this.msisdn),
        password: this.password,
        login_tag: login_tag,
        utm_source: utm_source,
        utm_medium: utm_medium,
        utm_campaign: utm_campaign,
        referrer: referrer
      }))
          .then(res => {

            vm.loading = '';
            var status = res.data.status;

            if(parseInt(status) === 201 ) {

              this.action_verify_password = true;
              vm.setSuccess("Success", "Account created successful");
              return;
            }

            var profile = res.data.message;
            vm.setProfile(profile);
            var auth = profile.auth;
            vm.setAuth(auth);

            vm.setSuccess("Success", "Login successful");
            vm.placeBet();

          })
          .catch(err => {

            vm.loading = '';
            if (err.response) {

              vm.setError("Failed",err.response.data.message)
              console.log(JSON.stringify(err.response.data.message));

            }
            else if (err.request) {

              vm.setError("Network Error", "Check your network connection and try again")
              console.log(JSON.stringify(err.request));

            }
            else {

              vm.setError("Network Error", "Check your network connection and try again")
              console.log(JSON.stringify(err));

            }

          })

    },

    verifyAccount: function () {

      this.reset();
      this.removeAuth();

      if (this.code.length < 4) {

        this.setError("Invalid Code", "Please enter a valid verification code");
        return;

      }

      this.loading = 'loading';

      var vm = this;

      var path = process.env.VUE_APP_URL_VERIFY;

      var currentDate = new Date();
      var login_tag = parseInt(currentDate.getTime()+''+Math.floor(Math.random() * (9 * (Math.pow(10, 2)))) + (Math.pow(10, 2)));
      this.setValue("login_tag",login_tag);

      axios.post(path, JSON.stringify({
        m: vm.getBackendValue(this.msisdn),
        code: this.code,
        login_tag: login_tag
      }))
          .then(res => {

            vm.loading = '';
            var profile = res.data.message;
            vm.setProfile(profile);
            var auth = profile.auth;
            vm.setAuth(auth);

            console.log('verifyAccount got auth '+auth);

            vm.placeBet();
            vm.setSuccess("Your account has been verified successfully");

          })
          .catch(err => {

            vm.loading = '';
            if (err.response) {

              vm.setError("Failed",err.response.data.message)
              console.log(JSON.stringify(err.response.data.message));

            }
            else if (err.request) {

              vm.setError("Network Error", "Check your network connection and try again")
              console.log(JSON.stringify(err.request));

            }
            else {

              vm.setError("Network Error", "Check your network connection and try again")
              console.log(JSON.stringify(err));

            }

          })

    },

    pickOutcome: function (outcome){

      this.outcome = outcome;
      this.odd_id = outcome.odd_id;
      var odd = outcome.odd;
      this.possible_win = this.stake * odd;
      this.tax = (this.possible_win - this.stake) * 0; //0.2
      this.payout = this.possible_win - this.stake
    },
    getHomeCompetitorName: function (matchName) {

      if (matchName === undefined) {

        return "";
      }

      return matchName.split('vs.')[0];
    },

    getAwayCompetitorName: function (matchName) {

      if (matchName === undefined) {

        return "";
      }
      return matchName.split('vs.')[1];
    },

    getMatch: function () {

      var vm = this;
      var path = process.env.VUE_APP_URL_FREEBET;

      axios.post(path, JSON.stringify({game_id: this.game_id}))
          .then(res => {

            if(Array.isArray(res.data.message)) {

              vm.fixture = res.data.message[0];

            } else {

              vm.fixture = res.data.message

            }

            vm.pickOutcome(vm.fixture.draw);

          })
          .catch(error => {

            console.log(error)

          })
    },
    getOddDirectionClass: function (outcome) {

      if(parseInt(outcome.active) === 0 || parseInt(outcome.status) !== 0) {

        return '';
      }

      if(parseFloat(outcome.odd) >= parseFloat(outcome.previous_odds) ) {

        return 'arrow green up';

      }

      return 'arrow red down';

    }

  },
  filters: {

    amount: function (val){

      if(val === undefined ) {

        return val

      }

      return parseFloat(val).toFixed(2).toLocaleString()

    }

  }
}
</script>